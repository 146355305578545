import React, { useState } from 'react'
import '../styles/HeaderFooter.scss'
import { graphql, useStaticQuery } from 'gatsby'
import facebook from '../images/facebook.png'
import linkin from '../images/linkin.png'
import close from '../images/close.png'
import abstr1 from '../images/abstract/1.png'

const HeaderFooter = ({ what, lang, setLang }) => {
  const { cockpitNavigation, cockpitContactsSection, cockpitNavigationRu } =
    useStaticQuery(graphql`
      query MyQuery1 {
        cockpitNavigation {
          LogoImage {
            value {
              publicURL
            }
          }
          LogoName {
            value
          }
          NavigationItems {
            value {
              First_Item {
                value
              }
              Second_Item {
                value
              }
              Third_Item {
                value
              }
            }
          }
        }
        cockpitContactsSection {
          LinkIn {
            value
          }
          FacebookLink {
            value
          }
        }
        cockpitNavigationRu: cockpitNavigation(lang: { eq: "ru" }) {
          NavigationItems {
            value {
              First_Item {
                value
              }
              Second_Item {
                value
              }
              Third_Item {
                value
              }
            }
          }
        }
      }
    `)
  const { LogoImage, LogoName, NavigationItems } = cockpitNavigation
  const { First_Item, Second_Item, Third_Item } = NavigationItems.value
  const {
    First_Item: First_Item_Ru,
    Second_Item: Second_Item_Ru,
    Third_Item: Third_Item_Ru,
  } = cockpitNavigationRu.NavigationItems.value
  const { LinkIn, FacebookLink } = cockpitContactsSection
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
    document.body.style.overflowY = 'hidden'
  }

  const handleClose = () => {
    setIsOpen(false)
    document.body.style.overflowY = 'auto'
  }

  return (
    <>
      <div className="container">
        <div
          className={
            what === 'header' ? 'hf_wrapper header' : 'hf_wrapper footer'
          }
        >
          <div className="logo">
            <img
              src={`https://crss-ff89a.web.app${LogoImage.value.publicURL}`}
              alt=""
            />
            {LogoName.value}
          </div>

          <nav className="nav">
            <ul>
              <li className="nav_item">
                <a href="#main">
                  {lang === 'eng' ? First_Item.value : First_Item_Ru.value}
                </a>
              </li>
              <li className="nav_item">
                <a href="#about">
                  {lang === 'eng' ? Second_Item.value : Second_Item_Ru.value}
                </a>
              </li>
              <li className="nav_item">
                <a href="#contacts">
                  {lang === 'eng' ? Third_Item.value : Third_Item_Ru.value}
                </a>
              </li>
              <li className="nav_item">
                <button
                  className="change_lang"
                  onClick={() => setLang(lang === 'eng' ? 'ru' : 'eng')}
                >
                  {lang === 'eng' ? 'Русский' : 'English'}
                </button>
              </li>

              {what === 'footer' && (
                <div className="sm">
                  <a href={FacebookLink.value} className="sm_item">
                    <img src={facebook} alt="" />
                  </a>
                  <a href={LinkIn.value} className="sm_item">
                    <img src={linkin} alt="" />
                  </a>
                </div>
              )}
            </ul>
          </nav>
          {what === 'header' && (
            <div className="hamburger" onClick={handleOpen}>
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          )}
        </div>
      </div>
      {what === 'header' && (
        <div
          className="mob_nav_wrapper"
          style={{ transform: `translateX(${isOpen ? 0 : 100}%)` }}
        >
          <img
            src={close}
            className={isOpen ? 'close anim' : 'close'}
            alt=""
            onClick={handleClose}
          />
          <nav>
            <ul>
              <li className="mob_nav_item">
                <a href="#main" onClick={handleClose}>
                  {lang === 'eng' ? First_Item.value : First_Item_Ru.value}
                </a>
              </li>
              <li className="mob_nav_item">
                <a href="#about" onClick={handleClose}>
                  {lang === 'eng' ? Second_Item.value : Second_Item_Ru.value}
                </a>
              </li>
              <li className="mob_nav_item">
                <a href="#contacts" onClick={handleClose}>
                  {lang === 'eng' ? Third_Item.value : Third_Item_Ru.value}
                </a>
              </li>
              <li>
                <button
                  className="change_lang"
                  onClick={() => setLang(lang === 'eng' ? 'ru' : 'eng')}
                >
                  {lang === 'eng' ? 'Русский' : 'English'}
                </button>
              </li>
            </ul>
            <img src={abstr1} alt="" />
          </nav>
        </div>
      )}
    </>
  )
}

export default HeaderFooter
