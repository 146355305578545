import React from 'react'
import '../styles/About.scss'
import { graphql, useStaticQuery } from 'gatsby'
import abstr3 from '../images/abstract/3.png'
import abstr5 from '../images/abstract/5.png'

const AboutSection = ({ lang }) => {
  const { cockpitAboutSection, cockpitAboutSectionRu } = useStaticQuery(graphql`
    query MyQuery3 {
      cockpitAboutSection {
        LeftText {
          value
        }
        RightText1 {
          value
        }
        RightText2 {
          value
        }
      }
      cockpitAboutSectionRu: cockpitAboutSection(lang: { eq: "ru" }) {
        LeftText {
          value
        }
        RightText1 {
          value
        }
        RightText2 {
          value
        }
      }
    }
  `)
  const { LeftText, RightText1, RightText2 } = cockpitAboutSection
  const {
    LeftText: LeftTextRu,
    RightText1: RightText1Ru,
    RightText2: RightText2Ru,
  } = cockpitAboutSectionRu
  return (
    <section id="about" className="about_section">
      <div className="container">
        <div className="about">
          <div className="left">
            {lang === 'eng' ? LeftText.value : LeftTextRu.value}
          </div>
          <div className="right">
            <p>{lang === 'eng' ? RightText1.value : RightText1Ru.value}</p>
            <p>{lang === 'eng' ? RightText2.value : RightText2Ru.value}</p>
          </div>
        </div>
      </div>
      <img src={abstr3} className="abstr3" alt="" />
      <img src={abstr3} className="abstr3_duplicate" alt="" />
      <img src={abstr5} className="abstr5_about" alt="" />
    </section>
  )
}

export default AboutSection
