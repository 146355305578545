import React from 'react'
import HeaderFooter from './HeaderFooter'
import { graphql, useStaticQuery } from 'gatsby'
import mainImg from '../images/main.png'
import '../styles/Main.scss'
import abstr1 from '../images/abstract/1.png'
import abstr2 from '../images/abstract/2.png'
import abstr5 from '../images/abstract/5.png'

const MainSection = ({ lang, setLang }) => {
  const { cockpitMainSection, cockpitMainSectionRu } = useStaticQuery(graphql`
    query MyQuery2 {
      cockpitMainSection {
        Title {
          value
        }
        Subtitle {
          value
        }
        SecondParagraph {
          value {
            Content {
              value
            }
            Title {
              value
            }
          }
        }
        FirstParagraph {
          value {
            Content {
              value
            }
            Title {
              value
            }
          }
        }
      }
      cockpitMainSectionRu: cockpitMainSection(lang: { eq: "ru" }) {
        FirstParagraph {
          value {
            Content {
              value
            }
            Title {
              value
            }
          }
        }
        SecondParagraph {
          value {
            Content {
              value
            }
            Title {
              value
            }
          }
        }
        Title {
          value
        }
        Subtitle {
          value
        }
      }
    }
  `)
  const { Title, Subtitle, FirstParagraph, SecondParagraph } =
    cockpitMainSection
  const {
    Title: TitleRu,
    Subtitle: SubtitleRu,
    FirstParagraph: FirstParagraphRu,
    SecondParagraph: SecondParagraphRu,
  } = cockpitMainSectionRu
  return (
    <section id="main" className="main">
      <header>
        <HeaderFooter what="header" lang={lang} setLang={setLang} />
      </header>
      <div className="container">
        <div className="main_info">
          <div>
            <h1>{lang === 'eng' ? Title.value : TitleRu.value}</h1>
            <p>{lang === 'eng' ? Subtitle.value : SubtitleRu.value}</p>
          </div>
          <img src={mainImg} alt="" />
        </div>
        <div className="ambitions">
          <div className="ambitions_item">
            <h3>
              {lang === 'eng'
                ? FirstParagraph.value.Title.value
                : FirstParagraphRu.value.Title.value}
              <img src={abstr5} alt="" />
            </h3>
            <p>
              {lang === 'eng'
                ? FirstParagraph.value.Content.value
                : FirstParagraphRu.value.Content.value}
            </p>
          </div>
          <div className="ambitions_item">
            <h3>
              {lang === 'eng'
                ? SecondParagraph.value.Title.value
                : SecondParagraphRu.value.Title.value}
            </h3>
            <p>
              {lang === 'eng'
                ? SecondParagraph.value.Content.value
                : SecondParagraphRu.value.Content.value}
            </p>
          </div>
        </div>
      </div>
      <img src={abstr1} className="abstr1" alt="" />
      <img src={abstr2} className="abstr2" alt="" />
    </section>
  )
}

export default MainSection
