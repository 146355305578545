import React, { useState } from "react"
import MainSection from "../components/MainSection"
import Seo from "../components/seo"
import "../styles/index.scss"
import "../font/font-rules.css"
import AboutSection from "../components/AboutSection"
import ContactsSection from "../components/ContactsSection"
import HeaderFooter from "../components/HeaderFooter"

const IndexPage = () => {
  const [lang, setLang] = useState("eng")
  return (
    <>
      <Seo title="CRS" />
      <MainSection lang={lang} setLang={l => setLang(l)} />
      <AboutSection lang={lang} />
      <ContactsSection lang={lang} />
      <footer>
        <HeaderFooter what="footer" lang={lang} />
      </footer>
    </>
  )
}

export default IndexPage
