import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import email from '../images/email.png'
import call from '../images/call.png'
import location from '../images/location.png'
import '../styles/Contacts.scss'
import abstr6 from '../images/abstract/6.png'
import facebook from '../images/facebook.png'
import linkin from '../images/linkin.png'
import abstr7 from '../images/abstract/7.png'
import Loader from 'react-loader-spinner'

const ContactsSection = ({ lang }) => {
  console.log(lang)
  const { cockpitContactsSection, cockpitContactsSectionRu } =
    useStaticQuery(graphql`
      query MyQuery {
        cockpitContactsSection {
          Title {
            value
          }
          Subtitle {
            value
          }
          Phone1 {
            value
          }
          Phone2 {
            value
          }
          LinkIn {
            value
          }
          FacebookLink {
            value
          }
          Email {
            value
          }
          Location1 {
            value
          }
          Location1Link {
            value
          }
          Location2 {
            value
          }
          Location2Link {
            value
          }
        }
        cockpitContactsSectionRu: cockpitContactsSection(lang: { eq: "ru" }) {
          Title {
            value
          }
          Subtitle {
            value
          }
          Location1 {
            value
          }
          Location2 {
            value
          }
        }
      }
    `)
  const {
    Title,
    Subtitle,
    Phone1,
    Phone2,
    Email,
    LinkIn,
    FacebookLink,
    Location1,
    Location1Link,
    Location2,
    Location2Link,
  } = cockpitContactsSection

  const {
    Title: TitleRu,
    Subtitle: SubtitleRu,
    Location1: Location1Ru,
    Location2: Location2Ru,
  } = cockpitContactsSectionRu

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [inputError, setInputError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    if (name.length > 2 && phone.length > 5) {
      setIsLoading(true)
      const res = await fetch('/sendData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          phone,
        }),
      })
      setIsLoading(false)
      setInputError(false)
      setPhone('')
      setName('')
    } else {
      setPhone('')
      setName('')
      setInputError(true)
    }
  }
  return (
    <section className="contacts_section" id="contacts">
      <div className="container">
        <div className="contacts">
          <div>
            <h2>{lang === 'eng' ? Title.value : TitleRu.value}</h2>
            <p>{lang === 'eng' ? Subtitle.value : SubtitleRu.value}</p>

            <form onSubmit={handleSubmit}>
              {!isLoading ? (
                <>
                  <input
                    type="text"
                    placeholder={
                      inputError
                        ? `${
                            lang === 'eng'
                              ? 'Name is wrong'
                              : 'Имя некорректное'
                          }`
                        : `${lang === 'eng' ? 'Name' : 'Имя'}`
                    }
                    onChange={e => setName(e.target.value)}
                    value={name}
                    className={inputError ? 'error' : ''}
                    required
                  />
                  <input
                    type="text"
                    placeholder={
                      inputError
                        ? `${
                            lang === 'eng'
                              ? 'Phone is wrong'
                              : 'Телефон некорректный'
                          }`
                        : `${lang === 'eng' ? 'Phone' : 'Телефон'}`
                    }
                    onChange={e => setPhone(e.target.value)}
                    value={phone}
                    className={inputError ? 'error' : ''}
                    required
                  />
                  <button type="submit">
                    {lang === 'eng' ? 'Send' : 'Отправить'}
                  </button>
                </>
              ) : (
                <Loader type="Bars" color="#fff" height={100} width={100} />
              )}
            </form>
          </div>
          <div className="contacts_items">
            <ul>
              <li className="contact_item">
                <img src={email} alt="" />
                <a href={`mailto: ${Email.value}`}>{Email.value}</a>
              </li>
              <li className="contact_item phone">
                <img src={call} alt="" />
                <a href={`tel: ${Phone1.value}`}>{Phone1.value}</a>
                <a href={`tel: ${Phone2.value}`}>{Phone2.value}</a>
              </li>
              <li className="contact_item">
                <img src={location} alt="" />
                <a href={Location1Link.value}>
                  {lang === 'eng' ? Location1.value : Location1Ru.value}
                </a>
              </li>
              <li className="contact_item">
                <img src={location} alt="" />
                <a href={Location2Link.value}>
                  {lang === 'eng' ? Location2.value : Location2Ru.value}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contacts_sm">
        <a href={FacebookLink.value} className="contacts_sm_item">
          <img src={facebook} alt="" />
          <span>Facebook</span>
        </a>
        <a href={LinkIn.value} className="contacts_sm_item">
          <img src={linkin} alt="" />
          <span>Linkedin</span>
        </a>
      </div>
      <img src={abstr6} className="abstr6" alt="" />
      <img src={abstr7} className="abstr7" alt="" />
    </section>
  )
}

export default ContactsSection
